'use client'

import { MouseEvent } from 'react'
import { Button, Icon } from '@vinted/web-ui'
import { useInView } from 'react-intersection-observer'
import { Stats16 } from '@vinted/monochrome-icons'

import { ProductItemModel } from 'types/models'
import { getProductItemStatus } from 'data/utils/item'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent, favouriteItemEvent, impressionEvent } from 'libs/common/event-tracker/events'
import { ListItemContentType } from 'constants/tracking/content-types'

import useTracking from 'hooks/useTracking'
import ProfileItemBumpButton from 'components/ProfileItemBumpButton'
import { ProductItem } from 'components/ProductItem'
import { ContentSource } from 'constants/tracking/content-sources'
import { ITEM_URL } from 'constants/routes'

type Props = {
  item: ProductItemModel
  viewingSelf: boolean
  allowBump: boolean
  isDescriptionHidden: boolean
  index: number
  onClick?: (event: MouseEvent) => void
}

const FeaturedItem = ({
  item,
  onClick,
  viewingSelf = false,
  allowBump = false,
  isDescriptionHidden,
  index,
}: Props) => {
  const { track } = useTracking()

  const { ref } = useInView({
    triggerOnce: true,
    onChange: inView => {
      if (inView && !viewingSelf) {
        track(
          impressionEvent({
            id: item.id,
            position: index + 1,
            contentType: ListItemContentType.Item,
            contentSource: ContentSource.FeaturedCollection,
            itemOwnerId: item.user.id,
          }),
        )
      }
    },
  })

  const onActionClick = (target: ClickableElement, itemId: number) => () => {
    const trackingEvent = clickEvent({
      target,
      targetDetails: itemId.toString(),
    })

    track(trackingEvent)
  }

  function handleFavouriteToggle({
    itemId,
    isFollowEvent,
  }: {
    itemId: number
    isFollowEvent: boolean
  }) {
    track(clickEvent({ target: ClickableElement.Favourite }))
    track(
      favouriteItemEvent({
        itemId,
        isFollowEvent,
        contentSource: ContentSource.FeaturedCollection,
      }),
    )
  }

  const renderPerformanceChartButton = (id: number) => (
    <Button
      icon={<Icon name={Stats16} color="parent" />}
      theme="primary"
      size={Button.Size.Small}
      url={ITEM_URL(id)}
      onClick={onActionClick(ClickableElement.ShowItemPerformance, id)}
      testId="featured-item--stats"
    />
  )

  function renderItemFooter() {
    if (!viewingSelf || isDescriptionHidden) return null

    const { id } = item
    const status = getProductItemStatus(item, viewingSelf)

    switch (status) {
      case 'bumped': {
        return renderPerformanceChartButton(id)
      }

      default: {
        if (!allowBump) return null

        return <ProfileItemBumpButton item={item} />
      }
    }
  }

  return (
    <div className="featured-collection__item" ref={ref} data-testid="featured_collection_item">
      <ProductItem
        item={item}
        showOwner={false}
        showStatus
        renderFooter={renderItemFooter()}
        onClick={onClick}
        viewingSelf={viewingSelf}
        testId="featured-item"
        hideBusinessBadge
        isDescriptionHidden={isDescriptionHidden}
        onFavouriteToggle={handleFavouriteToggle}
        isBumpTextHidden
      />
    </div>
  )
}

export default FeaturedItem
