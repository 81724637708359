'use client'

import { Spacer } from '@vinted/web-ui'

import { MEMBER_FOLLOWERS_URL, MEMBER_FOLLOWING_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from 'libs/common/event-tracker/events'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

type Props = {
  followersCount: number
  followingCount: number
  userId: number
  viewingSelf: boolean
}

enum FollowType {
  Following = 'Following',
  Followers = 'Followers',
}

const FollowInfo = ({ followersCount, followingCount, userId, viewingSelf }: Props) => {
  const translate = useTranslate('user')
  const { track } = useTracking()

  const followersURL = (id: number) => {
    return MEMBER_FOLLOWERS_URL(id)
  }

  const followingURL = (id: number) => {
    return MEMBER_FOLLOWING_URL(id)
  }

  const handleFollowClick = (type: string) => {
    if (viewingSelf)
      track(clickEvent({ screen: Screen.CurrentUserProfile, target: ClickableElement[type] }))
  }

  return (
    <div className="u-flexbox u-flex-wrap">
      <div className="u-flexbox">
        <a href={followersURL(userId)} onClick={() => handleFollowClick(FollowType.Followers)}>
          {followersCount}
        </a>
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        {translate('followers', undefined, { count: followersCount })}
        <span>,</span>
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
      </div>
      <div className="u-flexbox">
        <a href={followingURL(userId)} onClick={() => handleFollowClick(FollowType.Following)}>
          {followingCount}
        </a>
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        {translate('following', undefined, { count: followingCount })}
      </div>
    </div>
  )
}

export default FollowInfo
