'use client'

import { Dialog } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

type Props = {
  isShown: boolean
  onClose: () => void
}

const ErrorModal = ({ isShown, onClose }: Props) => {
  const translate = useTranslate()

  return (
    <Dialog
      show={isShown}
      title={translate('errors.modal.title')}
      body={translate('errors.modal.body')}
      actions={[
        {
          text: translate('actions.close'),
          callback: onClose,
        },
      ]}
    />
  )
}

export default ErrorModal
