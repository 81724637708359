import useAbTest from 'hooks/useAbTest'
import useFeatureSwitch from 'hooks/useFeatureSwitch'

import { AbTestVariant } from 'constants/abtest'

const useMultipleCollections = () => {
  const multipleCollectionsV2AbTest = useAbTest({
    abTestName: 'multiple_collections_v2',
  })
  const isMultipleCollectionsFSEnabled = useFeatureSwitch('multiple_collections')

  const isMultipleCollectionsAbTestEnabled =
    !!multipleCollectionsV2AbTest?.variant &&
    multipleCollectionsV2AbTest?.variant === AbTestVariant.On

  return isMultipleCollectionsFSEnabled || isMultipleCollectionsAbTestEnabled
}

export default useMultipleCollections
