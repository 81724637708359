import { escape } from 'lodash'

import { hashtagsToCatalogLinks } from 'libs/utils/hashtag'
import { convertUrls } from 'libs/utils/url'
import { LinkReplacement, LinkifiedText } from 'types/linkify'

type Replacement = {
  url: LinkReplacement
  hashtag: LinkReplacement
}

const linkifyContent = (
  text: string,
  replacement: Replacement,
  escapeText?: boolean,
  urlRegex?: RegExp,
): LinkifiedText => {
  try {
    let linkifiedText = text

    if (escapeText) linkifiedText = escape(linkifiedText)

    const formattedHashTags = hashtagsToCatalogLinks(linkifiedText, replacement.hashtag)
    const formattedUrls = convertUrls(formattedHashTags.text, replacement.url, urlRegex)

    const values = {
      ...formattedHashTags.values,
      ...formattedUrls.values,
    }

    return {
      text: formattedUrls.text,
      values: Object.keys(values).length ? values : undefined,
    }
  } catch (exception) {
    return { text }
  }
}

export { linkifyContent }
