'use client'

import { ReactElement, memo } from 'react'
import { isEqual, noop } from 'lodash'

import ErrorBoundary from 'components/ErrorBoundary'
import { GridItem, GridItemRenderProps, ItemVisibleCallbackArgs } from 'types/components'

import GridItemComponent from './GridItem/GridItem'

type ItemGridProps<T> = {
  items: Array<GridItem<T>>
  renderItem: (props: GridItemRenderProps<T>) => ReactElement | null
  widthResolver?: (item: GridItem<T>) => string | null
  onItemVisible?: (callbackArgs: ItemVisibleCallbackArgs<T>) => void
  handleErrorLogging?: (err: Error | null) => void
  preventLog?: boolean
}

const ItemGrid = <T,>({
  items,
  renderItem,
  widthResolver = () => null,
  onItemVisible = noop,
  handleErrorLogging,
  preventLog,
}: ItemGridProps<T>) => {
  const ItemComponent = renderItem

  return (
    <div className="feed-grid">
      {items.map((item, index) => (
        <GridItemComponent
          key={item.id}
          width={widthResolver(item)}
          onVisible={() => onItemVisible({ item, index })}
        >
          <ErrorBoundary
            FallbackComponent={ErrorBoundary.ComponentError}
            preventLog={preventLog}
            onError={handleErrorLogging}
          >
            <ItemComponent item={item} index={index} />
          </ErrorBoundary>
        </GridItemComponent>
      ))}
    </div>
  )
}

// type assertion required for generics to work
export default memo(ItemGrid, isEqual) as typeof ItemGrid
