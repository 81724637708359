import { useEffect } from 'react'

import { getCollections } from 'data/api'
import { transformCollectionsResponse } from 'data/api/transformers/response'

import useFetch from './useFetch'

const useFetchCollections = (userId: number | undefined) => {
  const {
    fetch: fetchCollections,
    transformedData: collectionsData,
    isLoading,
    error,
  } = useFetch(getCollections, transformCollectionsResponse)

  useEffect(() => {
    if (!userId) return
    fetchCollections({ userId })
  }, [fetchCollections, userId])

  return { collectionsData, isLoading, error }
}

export default useFetchCollections
