'use client'

import { Button, Icon } from '@vinted/web-ui'
import { Stats16 } from '@vinted/monochrome-icons'

import { FormattedMessage } from 'components/@support'
import ItemGrid from 'components/ItemGrid'
import { ProductItem } from 'components/ProductItem'
import ProfileItemBumpButton from 'components/ProfileItemBumpButton'
import { addClickListItemTracking } from 'containers/TrackingProvider'
import useTracking from 'hooks/useTracking'

import { ItemAlertStatus } from 'constants/item'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'
import { EDIT_ITEM_URL, ITEM_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'

import { getProductItemStatus } from 'data/utils/item'
import { clickEvent, impressionEvent } from 'libs/common/event-tracker/events'
import { GridItemRenderProps, ItemVisibleCallbackArgs } from 'types/components'
import { logClosetItemError } from 'pages/Profile/utils/log'
import { ProductItemModel } from 'types/models'

type Props = {
  items: Array<ProductItemModel>
  userId: number
  allowBump: boolean
  isViewingSelf: boolean
}

const TrackedProductItem = addClickListItemTracking(ProductItem)

const ItemsContainer = ({ items, userId, allowBump, isViewingSelf }: Props) => {
  const { track } = useTracking()

  const handleItemVisibility = ({
    item: { data, id },
    index,
  }: ItemVisibleCallbackArgs<ProductItemModel>) => {
    const event = impressionEvent({
      id,
      position: index + 1,
      contentType: ListItemContentType.Item,
      contentSource: ContentSource.UserItems,
      itemOwnerId: data.user.id || userId,
    })

    track(event)
  }

  const handleErrorLogging = (err: Error | null) => logClosetItemError(err)

  const onActionClick = (target: ClickableElement, itemId: number) => () => {
    const trackingEvent = clickEvent({
      target,
      targetDetails: itemId.toString(),
    })

    track(trackingEvent)
  }

  const renderPerformanceChartButton = (id: number) => (
    <Button
      icon={<Icon name={Stats16} color="parent" />}
      theme="primary"
      testId="bump-performance-button"
      size={Button.Size.Small}
      url={ITEM_URL(id)}
      onClick={onActionClick(ClickableElement.ShowItemPerformance, id)}
    />
  )

  const resolveItemWidth = () => 'one-fifth'

  const renderItemFooter = (item: ProductItemModel) => {
    const { id } = item

    if (!isViewingSelf) return null

    const status = getProductItemStatus(item, isViewingSelf)

    switch (status) {
      case ItemAlertStatus.ReplicaProof:
      case ItemAlertStatus.UnderReview:
        return (
          <Button
            text={<FormattedMessage id="item.actions.edit" />}
            theme="primary"
            size={Button.Size.Small}
            url={EDIT_ITEM_URL(id)}
          />
        )

      case 'bumped': {
        return renderPerformanceChartButton(id)
      }

      case 'draft': {
        return (
          <Button
            text={<FormattedMessage id="item.drafts.finish_editing" />}
            theme="primary"
            size={Button.Size.Small}
            url={EDIT_ITEM_URL(id)}
          />
        )
      }

      default: {
        if (!allowBump) return null

        return <ProfileItemBumpButton item={item} />
      }
    }
  }

  const renderItem = ({ item: { data }, index }: GridItemRenderProps<ProductItemModel>) => (
    <TrackedProductItem
      showOwner={false}
      item={data}
      renderFooter={renderItemFooter(data)}
      showStatus
      viewingSelf={isViewingSelf}
      tracking={{
        id: data.id,
        contentType: ListItemContentType.Item,
        contentSource: ContentSource.UserItems,
        position: index + 1,
        screen: isViewingSelf ? Screen.CurrentUserProfile : Screen.UserProfile,
      }}
      url={data.isDraft ? EDIT_ITEM_URL(data.id) : ITEM_URL(data.id)}
      hideBusinessBadge
      isBumpTextHidden
    />
  )

  return (
    <ItemGrid
      items={items.map(item => ({ id: item.id, data: item }))}
      renderItem={renderItem}
      onItemVisible={handleItemVisibility}
      widthResolver={resolveItemWidth}
      preventLog
      handleErrorLogging={handleErrorLogging}
    />
  )
}

export default ItemsContainer
