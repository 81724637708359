'use client'

import { useState } from 'react'

import useTracking from 'hooks/useTracking'
import useMultipleCollections from 'components/FeaturedCollection/useMultipleCollections'

import { navigateToPage } from 'libs/utils/window'
import { VasEntryPointModel } from 'types/models'
import { FEATURED_COLLECTION_URL } from 'constants/routes'
import { clickEvent } from 'libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { VasImages } from 'constants/vas-entry-point'

import FeaturedCollectionOnboardingModal from 'components/FeaturedCollection/FeaturedCollectionOnboardingModal'
import MultipleCollectionsOnboarding from 'components/FeaturedCollection/MultipleCollectionsOnboarding'

import VasBanner from '../../VasBanner'

type Props = {
  collectionsBanner?: VasEntryPointModel
}

const CollectionsBanner = ({ collectionsBanner }: Props) => {
  const { track } = useTracking()
  const [isCollectionOnboardingOpen, setIsCollectionOnboardingOpen] = useState(false)
  const isMultipleCollectionsEnabled = useMultipleCollections()
  if (!collectionsBanner) return null

  function handleFeaturedCollectionBannerClick() {
    setIsCollectionOnboardingOpen(true)

    track(
      clickEvent({
        screen: Screen.CurrentUserProfile,
        target: ClickableElement.PromoteFeaturedCollection,
        targetDetails: collectionsBanner?.locked ? 'locked' : 'not_locked',
      }),
    )
  }

  function handleCollectionOnboardingNextAction() {
    track(
      clickEvent({ screen: Screen.FeaturedCollection, target: ClickableElement.CreateCollection }),
    )

    navigateToPage(FEATURED_COLLECTION_URL)
  }

  function handleCollectionOnboardingBackAction() {
    setIsCollectionOnboardingOpen(false)
  }

  const bannerProps = {
    ...collectionsBanner,
    onClick: handleFeaturedCollectionBannerClick,
    testId: 'featured-collection-banner',
    icon: VasImages.VasFeaturedCollection,
  }

  return (
    <>
      <VasBanner {...bannerProps} />
      {!isMultipleCollectionsEnabled && (
        <FeaturedCollectionOnboardingModal
          show={isCollectionOnboardingOpen}
          isFeaturedCollectionCreated={false}
          onNextAction={handleCollectionOnboardingNextAction}
          onBack={handleCollectionOnboardingBackAction}
        />
      )}
      {isMultipleCollectionsEnabled && (
        <MultipleCollectionsOnboarding
          isOnboardingOpen={isCollectionOnboardingOpen}
          onClose={handleCollectionOnboardingBackAction}
        />
      )}
    </>
  )
}

export default CollectionsBanner
