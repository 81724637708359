'use client'

import { Component } from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { Button, Navigation, Cell, Spacer, Text, Divider, Image, Icon } from '@vinted/web-ui'
import { ArrowLeft24, Dots24 } from '@vinted/monochrome-icons'
import { compact } from 'lodash'
import { produce } from 'immer'

import { ROOT_CATALOG_ID, WEB_CATALOG_ROOT_ALL_CODE } from 'constants/catalog'

import SelectableItemList from 'components/SelectableItemList'
import ScrollableArea from 'components/ScrollableArea'
import FilterDropdown from 'components/FilterDropdown'
import { RenderItemProps } from 'components/SelectableItemList/SelectableItemList'

import { getCatalogPhotoSrc } from 'data/utils/catalog'
import { CatalogModel } from 'types/models'
import { SelectableListItem } from 'types/components'
import { catalogsInAscOrder } from 'libs/utils/catalog'

const DROPDOWN_MIN_WIDTH = 300
const SELECT_DROPDOWN_MAX_HEIGHT = 320
const ROOT_CATALOG_ALL_CODE = '_ALL_ROOT' as const

const catalogModelToCatalogListItem = (catalog: CatalogModel): CatalogItem => {
  const { id, title, catalogIds, parentId, code, depth, itemCount, photo } = catalog

  return {
    id,
    title,
    value: id,
    data: {
      id,
      catalogIds,
      parentId,
      code,
      depth,
      itemCount,
      photo,
    },
  }
}

type CatalogItemData = Pick<
  CatalogModel,
  'id' | 'catalogIds' | 'parentId' | 'code' | 'depth' | 'itemCount' | 'photo'
>
type CatalogItem = SelectableListItem<CatalogItemData, number>

type Props = {
  intl: IntlShape
  selectedId: null | number
  catalogs: Array<CatalogModel>
  toggleCatalogFilterOption: (item: any) => void
  onDropdownOpen?: () => void
}

type State = {
  navigatedCatalogId: number
}

class CatalogFilter extends Component<Props, State> {
  state: Readonly<State> = {
    navigatedCatalogId: ROOT_CATALOG_ID,
  }

  translate = (id: string) => {
    const { intl } = this.props

    return intl.formatMessage({ id: `user.items.filters.catalog.${id}` })
  }

  getCatalogList(parentId: number) {
    const { catalogs } = this.props

    const parentCatalog = produce(
      catalogs.find(catalog => catalog.id === parentId),
      draft => {
        if (!draft) return

        draft.title = this.translate('all')
        draft.code = WEB_CATALOG_ROOT_ALL_CODE

        // 'All' catalog is placed alongside its children in the list.
        // Therefore, it must match children's depth.
        draft.depth += 1
      },
    )

    return compact([
      parentCatalog,
      ...catalogs.filter(catalog => catalog.parentId === parentId).sort(catalogsInAscOrder),
    ])
  }

  getCatalogListItems = (parentId: number) => {
    const catalogList = this.getCatalogList(parentId)
    const catalogListItems = catalogList.map(catalogModelToCatalogListItem)

    if (parentId === ROOT_CATALOG_ID) {
      catalogListItems.unshift({
        id: ROOT_CATALOG_ID,
        title: this.translate('all'),
        value: ROOT_CATALOG_ID,
        data: {
          id: ROOT_CATALOG_ID,
          catalogIds: [],
          parentId: ROOT_CATALOG_ID,
          code: ROOT_CATALOG_ALL_CODE,
          depth: 0,
          itemCount: catalogList.reduce((acc, { itemCount }) => acc + itemCount, 0),
          photo: null,
        },
      })
    }

    return catalogListItems
  }

  handleCatalogClick = (catalog: CatalogItem) => {
    const { toggleCatalogFilterOption } = this.props
    const data = catalog.data as CatalogItemData

    if (data.catalogIds.length > 0 && data.code !== WEB_CATALOG_ROOT_ALL_CODE) {
      this.setState({ navigatedCatalogId: Number(catalog.id) })

      return
    }

    if (data.code === ROOT_CATALOG_ALL_CODE) {
      toggleCatalogFilterOption(null)

      return
    }

    if (catalog.id) toggleCatalogFilterOption(Number(catalog.id))
  }

  handleBackClick = (catalog: CatalogModel) => () => {
    this.setState({
      navigatedCatalogId: Number(catalog.parentId),
    })
  }

  handleOnDropdownOpen = () => {
    this.props.onDropdownOpen?.()
  }

  getSelectedName() {
    const { selectedId, catalogs } = this.props

    if (!selectedId) return this.translate('all')

    const selectedCatalog = catalogs.find(({ id }) => id === selectedId)

    return selectedCatalog ? selectedCatalog.title : ''
  }

  renderNavigation() {
    const { navigatedCatalogId } = this.state
    const { catalogs } = this.props
    const catalog = catalogs.find(({ id }) => id === navigatedCatalogId)

    if (!catalog) return null

    return (
      <>
        <Navigation
          left={
            <Button
              styling={Button.Styling.Flat}
              theme="amplified"
              icon={<Icon name={ArrowLeft24} />}
              onClick={this.handleBackClick(catalog)}
              testId="catalog-filter-go-back"
            />
          }
          body={catalog.title}
        />
        <Divider />
      </>
    )
  }

  renderCatalog = ({ item, itemElementProps }: RenderItemProps<CatalogItemData>) => {
    const data = item.data as CatalogItemData
    const { catalogIds, code, depth, photo } = data
    const icon = this.renderIcon(photo, code, depth)

    if (!catalogIds.length || code === WEB_CATALOG_ROOT_ALL_CODE) {
      return (
        <Cell
          {...itemElementProps}
          prefix={icon}
          suffix={
            <div className="u-flexbox u-align-items-center">
              {data.itemCount}
              <Spacer orientation={Spacer.Orientation.Vertical} />
              {itemElementProps.suffix}
            </div>
          }
        />
      )
    }

    return <Cell {...itemElementProps} prefix={icon} suffix={data.itemCount} chevron />
  }

  // TODO: convert into functional component
  // eslint-disable-next-line class-methods-use-this
  renderIcon = (photo: CatalogModel['photo'], code: string, depth: number) => {
    if (depth > 1) return null

    if (code === WEB_CATALOG_ROOT_ALL_CODE || code === ROOT_CATALOG_ALL_CODE)
      return <Icon name={Dots24} color={Icon.Color.Primary} />

    if (!photo) return null

    return <Image {...getCatalogPhotoSrc(photo)} size={Image.Size.Regular} />
  }

  renderDropdown() {
    const { selectedId } = this.props
    const { navigatedCatalogId } = this.state
    const items = this.getCatalogListItems(navigatedCatalogId)
    const selected = selectedId === null ? [-1] : [selectedId]

    return (
      <div className="u-ui-padding-vertical-x-small">
        {this.renderNavigation()}

        <ScrollableArea maxHeight={SELECT_DROPDOWN_MAX_HEIGHT}>
          <SelectableItemList
            name="catalog_ids"
            isMultiSelect={false}
            items={items}
            selected={selected}
            onItemClick={this.handleCatalogClick}
            renderItem={this.renderCatalog}
          />
        </ScrollableArea>
      </div>
    )
  }

  render() {
    return (
      <div className="u-flexbox u-align-items-center">
        <Text as="h3" theme="amplified" type={Text.Type.Subtitle} text={this.translate('name')} />
        <Spacer orientation={Spacer.Orientation.Vertical} />
        <FilterDropdown
          title={this.getSelectedName()}
          minWidth={DROPDOWN_MIN_WIDTH}
          placement="bottom-end"
          onOpen={this.handleOnDropdownOpen}
          testId="catalog-filter-dropdown"
        >
          {this.renderDropdown()}
        </FilterDropdown>
      </div>
    )
  }
}

export default injectIntl(CatalogFilter)
