'use client'

import { useEffect, useState } from 'react'
import { minBy } from 'lodash'

import useTracking from 'hooks/useTracking'
import useFetch from 'hooks/useFetch'

import { getCollectionsPlansPricing } from 'data/api'
import { transformCollectionsPlansPricingResponse } from 'data/api/transformers/response'
import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import MultipleCollectionsPlansModal from './MultipleCollectionsPlansModal'
import MultipleCollectionsOnboardingModal from './MultipleCollectionsOnboardingModal'

enum CollectionsModal {
  Onboarding = 'onboarding',
  Plans = 'plans',
}

type Props = {
  isOnboardingOpen: boolean
  onClose: () => void
}

const MultipleCollectionsOnboarding = ({ isOnboardingOpen, onClose }: Props) => {
  const { track } = useTracking()
  const [activeModal, setActiveModal] = useState<CollectionsModal>()

  const {
    fetch: fetchPlansPricing,
    isLoading,
    transformedData: plansPricing,
    error,
  } = useFetch(getCollectionsPlansPricing, transformCollectionsPlansPricingResponse)

  const isFetchPlansPricingInitiated = !!plansPricing || isLoading || error
  const cheapestPlan = minBy(plansPricing?.collectionsPricing, plan =>
    parseFloat(plan.totalPrice.amount),
  )
  const maxActiveCollectionsCount = plansPricing
    ? Math.max(...plansPricing.collectionsPricing.map(plan => plan.maxActiveCount))
    : 0

  useEffect(() => {
    if (!isOnboardingOpen) return

    setActiveModal(CollectionsModal.Onboarding)
  }, [isOnboardingOpen])

  useEffect(() => {
    if (!isOnboardingOpen || isFetchPlansPricingInitiated) return

    fetchPlansPricing()
  }, [fetchPlansPricing, isOnboardingOpen, isFetchPlansPricingInitiated])

  function handlePriceInfoClick() {
    setActiveModal(CollectionsModal.Plans)

    track(
      clickEvent({
        screen: Screen.FeaturedCollection,
        target: ClickableElement.PriceInformation,
      }),
    )
  }

  function handlePlansModalBackClick() {
    setActiveModal(CollectionsModal.Onboarding)
  }

  function handleOnboardingClose() {
    setActiveModal(undefined)
    onClose()
  }

  return (
    <>
      <MultipleCollectionsOnboardingModal
        isShown={activeModal === CollectionsModal.Onboarding}
        isLoading={isLoading}
        cheapestPlan={cheapestPlan}
        maxActiveCollectionsCount={maxActiveCollectionsCount}
        onClose={handleOnboardingClose}
        onPriceInfoClick={handlePriceInfoClick}
        locked={plansPricing?.locked}
        lockedNote={plansPricing?.lockedNote}
      />
      {plansPricing && (
        <MultipleCollectionsPlansModal
          isShown={activeModal === CollectionsModal.Plans}
          plansPricing={plansPricing.collectionsPricing}
          onBack={handlePlansModalBackClick}
        />
      )}
    </>
  )
}

export default MultipleCollectionsOnboarding
