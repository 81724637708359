'use client'

import ContentLoader from 'components/ContentLoader'
import useAbTest from 'hooks/useAbTest'
import { SellerInsightsType } from 'constants/seller-insights'

import useGetPhotoRecommendations from './hooks/useGetPhotoRecommendations'
import SellerInsightsPhotoRecommendations from './SellerInsightsPhotoRecommendations/SellerInsightsPhotoRecommendations'
import SellerInsightsBlocks from './SellerInsightsBlocks'
import SellerInsightsTipsBanner from './SellerInsightsTipsBanner'

type Props = {
  sellerInsights: SellerInsightsType
  isSellerInsightsLoading: boolean
}

const SellerInsights = ({ sellerInsights, isSellerInsightsLoading }: Props) => {
  const { photoRecommendations, isPhotoRecommendationsLoading } = useGetPhotoRecommendations()

  const sellerInsightsTipsBannerAbTest = useAbTest({
    abTestName: 'seller_insights_tip_banner',
    shouldTrackExpose: true,
  })
  const isSellerInsightsTipsBannerEnabled =
    sellerInsightsTipsBannerAbTest && sellerInsightsTipsBannerAbTest.variant !== 'off'

  if (isSellerInsightsLoading || isPhotoRecommendationsLoading)
    return <ContentLoader testId="seller-insights-loader" />

  if (!sellerInsights?.isVisible) return null

  return (
    <section>
      <div className="seller-blocks-container">
        {isSellerInsightsTipsBannerEnabled && <SellerInsightsTipsBanner />}
        {sellerInsights?.isVisible && <SellerInsightsBlocks sellerInsights={sellerInsights} />}
      </div>
      {photoRecommendations && <SellerInsightsPhotoRecommendations {...photoRecommendations} />}
    </section>
  )
}

export default SellerInsights
