'use client'

import { Dialog } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

type Props = {
  isOpen: boolean
  onSubmit?: () => Promise<void>
  onClose?: () => void
}

const DeleteCollectionModal = ({ isOpen, onSubmit, onClose }: Props) => {
  const translate = useTranslate('featured_collection.modals.delete')

  return (
    <Dialog
      show={isOpen}
      title={translate('title')}
      body={translate('text')}
      actions={[
        {
          text: translate('action'),
          style: Dialog.ActionStyling.Filled,
          theme: 'warning',
          callback: onSubmit,
          testId: 'featured-collection-delete-modal--action-confirm',
        },
        {
          text: translate('secondary_action'),
          theme: 'warning',
          callback: onClose,
        },
      ]}
    />
  )
}

export default DeleteCollectionModal
