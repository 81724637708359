'use client'

import { Button, Icon } from '@vinted/web-ui'
import { ArrowBack12, Pencil12, X12 } from '@vinted/monochrome-icons'

import { FormattedMessage } from 'components/@support'
import SeparatedList from 'components/SeparatedList'

import { FeedbackScreen, useFeedbackContext } from '../../FeedbackProvider'

type Props = {
  id: number
  editFeedbackUrl: string
  canReply: boolean
  canChange: boolean
  canDelete: boolean
}

const FeedbackActions = ({ id, editFeedbackUrl, canChange, canDelete, canReply }: Props) => {
  const { setScreenById } = useFeedbackContext()

  const handleReply = () => {
    setScreenById(id, FeedbackScreen.CreateComment)
  }

  const handleDelete = () => {
    setScreenById(id, FeedbackScreen.DeleteFeedback)
  }

  return (
    <SeparatedList>
      {canReply && (
        <Button
          text={<FormattedMessage id="user.feedback.reply" />}
          icon={<Icon name={ArrowBack12} color="parent" />}
          inline
          size={Button.Size.Small}
          theme="muted"
          onClick={handleReply}
          testId="feedback-reply-action"
        />
      )}
      {canChange && (
        <Button
          text={<FormattedMessage id="user.feedback.edit" />}
          icon={<Icon name={Pencil12} color="parent" />}
          inline
          size={Button.Size.Small}
          theme="muted"
          url={editFeedbackUrl}
          testId="feedback-edit-action"
        />
      )}
      {canDelete && (
        <Button
          text={<FormattedMessage id="user.feedback.delete" />}
          icon={<Icon name={X12} color="parent" />}
          inline
          size={Button.Size.Small}
          theme="muted"
          onClick={handleDelete}
          testId="feedback-delete-action"
        />
      )}
    </SeparatedList>
  )
}

export default FeedbackActions
