'use client'

import { useState } from 'react'
import { Button, Cell, Spacer, Text } from '@vinted/web-ui'
import { isEmpty } from 'lodash'

import FeaturedCollectionOnboardingModal from 'components/FeaturedCollection/FeaturedCollectionOnboardingModal'
import useBreakpoint from 'hooks/useBreakpoint'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import { EDIT_FEATURED_COLLECTION_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from 'libs/common/event-tracker/events'

import { navigateToPage } from 'libs/utils/window'

import FeaturedCollectionBox from './FeaturedCollectionBox'
import useFetchFeaturedCollections from '../hooks/useFetchFeaturedCollections'

type Props = {
  userId: number
  viewingSelf: boolean
  allowBump: boolean
}

const ProfileCollections = ({ userId, viewingSelf, allowBump }: Props) => {
  const breakpoints = useBreakpoint()
  const { track } = useTracking()
  const translate = useTranslate()
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false)

  const { collections } = useFetchFeaturedCollections({ userId })

  const collection = collections ? collections[0] : null

  function handleActivateClick() {
    setIsOnboardingOpen(true)

    track(
      clickEvent({
        screen: Screen.CurrentUserProfile,
        target: ClickableElement.ActivateFeaturedCollection,
      }),
    )
  }

  function handleOnboardingNextAction() {
    if (!collection) return

    track(
      clickEvent({ screen: Screen.FeaturedCollection, target: ClickableElement.ReviewCollection }),
    )

    navigateToPage(EDIT_FEATURED_COLLECTION_URL(collection.id))
  }

  function handleOnboardingBackAction() {
    setIsOnboardingOpen(false)
  }

  function renderActivateCollection() {
    return (
      <Cell>
        <Button
          styling={Button.Styling.Filled}
          text={translate('featured_collection.actions.activate')}
          size={breakpoints.phones ? Button.Size.Medium : undefined}
          inline={!breakpoints.phones}
          onClick={handleActivateClick}
        />
        <Spacer
          size={Spacer.Size.Medium}
          orientation={
            breakpoints.phones ? Spacer.Orientation.Horizontal : Spacer.Orientation.Vertical
          }
        />
        <Text
          as="h4"
          type={Text.Type.Caption}
          text={translate('featured_collection.activation_note')}
        />
        <FeaturedCollectionOnboardingModal
          show={isOnboardingOpen}
          isFeaturedCollectionCreated
          onNextAction={handleOnboardingNextAction}
          onBack={handleOnboardingBackAction}
        />
      </Cell>
    )
  }

  if (!collection || (!viewingSelf && isEmpty(collection.items))) return null

  return (
    <>
      <Spacer size={Spacer.Size.Large} />
      <FeaturedCollectionBox
        {...collection}
        viewingSelf={viewingSelf}
        allowBump={allowBump}
        hasItems={!!collection.items.length}
      />
      {!collection.isActive && renderActivateCollection()}
    </>
  )
}

export default ProfileCollections
