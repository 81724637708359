'use client'

import { QuickRepliesBadge32, StarListerBadge32 } from '@vinted/multichrome-icons'
import { Cell, Text, Icon } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

export enum SellerBadgeType {
  ActiveLister = 'ACTIVE_LISTER',
  FastReplier = 'FAST_REPLIER',
}

export enum SellerBadgeTranslationKey {
  ActiveLister = 'active_lister',
  QuickReplier = 'quick_replier',
}

const SellerBadgeInfoByType = {
  [SellerBadgeType.ActiveLister]: {
    translationKey: SellerBadgeTranslationKey.ActiveLister,
    icon: StarListerBadge32,
  },
  [SellerBadgeType.FastReplier]: {
    translationKey: SellerBadgeTranslationKey.QuickReplier,
    icon: QuickRepliesBadge32,
  },
}

type Props = {
  type: SellerBadgeType
}

const SellerBadge = ({ type }: Props) => {
  const translate = useTranslate('user.seller_badges')

  const { translationKey, icon } = SellerBadgeInfoByType[type]

  return (
    <div className="u-flexbox u-background-white">
      <Cell
        styling={Cell.Styling.Tight}
        prefix={<Icon name={icon} aria={{ 'aria-hidden': 'true' }} />}
        title={translate(`${translationKey}.title`)}
        body={
          <Text text={translate(`${translationKey}.body`)} type={Text.Type.Subtitle} as="span" />
        }
      />
    </div>
  )
}

export default SellerBadge
