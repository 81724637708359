'use client'

import { ReactNode, useMemo } from 'react'

import useFetchVasEntryPoints from 'hooks/useFetchVasEntryPoints'
import useSession from 'hooks/useSession'

import { VasEntryPoint } from 'constants/vas-entry-point'

import VasEntryPointsContext from './VasEntryPointsContext'

type Props = {
  children: ReactNode
  userId: number
}

const VasEntryPointsProvider = ({ children, userId }: Props) => {
  const { user: currentUser } = useSession()

  const isViewingSelf = currentUser?.id === userId

  const { data: vasEntryPoints } = useFetchVasEntryPoints(
    [
      VasEntryPoint.ProfilePromotedClosets,
      VasEntryPoint.PromotedClosetsStats,
      VasEntryPoint.Collections,
      VasEntryPoint.PushUps,
    ],
    !isViewingSelf,
  )

  const value = useMemo(() => {
    return {
      vasEntryPoints,
    }
  }, [vasEntryPoints])

  return <VasEntryPointsContext.Provider value={value}>{children}</VasEntryPointsContext.Provider>
}

export default VasEntryPointsProvider
