import { uniq } from 'lodash'
import twitterText from 'twitter-text'

import { CATALOG_URL } from 'constants/routes'
import { toUrlQuery } from 'libs/utils/url'
import { LinkReplacement, LinkifiedText } from 'types/linkify'

const hashtagsToCatalogLinks = (text: string, replacement: LinkReplacement): LinkifiedText => {
  try {
    // Gets unique hashtags without hashtag symbol
    const tags = uniq(twitterText.extractHashtags(text)) || []
    const values = {}

    let transformedText = text

    tags.forEach((tag, index) => {
      const paramName = `hashtag${index + 1}`
      const hashtag = `#${tag}`
      const reg = new RegExp(hashtag, 'g')
      const url = `${CATALOG_URL}?${toUrlQuery({ search_text: hashtag })}`
      values[paramName] = replacement(url, hashtag)
      transformedText = transformedText.replace(reg, `{${paramName}}`)
    })

    return {
      text: transformedText,
      values,
    }
  } catch (ex) {
    return { text }
  }
}

export { hashtagsToCatalogLinks }
