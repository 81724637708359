'use client'

import { Button } from '@vinted/web-ui'

import { ProductItemModel } from 'types/models'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useStartBumpCheckout from 'hooks/useStartBumpCheckout'

import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { getBumpableItemDataFromModel } from 'data/utils/bump'

type Props = {
  item: ProductItemModel
}

const ProfileItemBumpButton = ({ item }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('item.actions')
  const { startCheckout } = useStartBumpCheckout()

  function handleOnClick() {
    startCheckout(getBumpableItemDataFromModel(item))

    track(clickEvent({ target: ClickableElement.PushUpItem, targetDetails: `${item.id}` }))
  }

  return (
    <Button
      disabled={!item.canPushUp}
      size={Button.Size.Small}
      text={translate('bump')}
      onClick={handleOnClick}
      testId="bump-button"
    />
  )
}

export default ProfileItemBumpButton
