'use client'

import { ReactNode } from 'react'
import { Card, Cell, Icon, Image, Text } from '@vinted/web-ui'
import { ChevronRight24 } from '@vinted/monochrome-icons'

import useAsset from 'hooks/useAsset'
import useAbTest from 'hooks/useAbTest'
import useTranslate from 'hooks/useTranslate'
import useSession from 'hooks/useSession'
import useFeatureSwitch from 'hooks/useFeatureSwitch'

import { REFERRALS_URL } from 'constants/routes'

type Props = {
  bottomSpacer?: ReactNode
}

const ReferralsBanner = ({ bottomSpacer }: Props) => {
  const translate = useTranslate('profile.banners.referrals')
  const isReferralsEnabled = useFeatureSwitch('referrals')
  const asset = useAsset('/assets/referrals')
  const { user } = useSession()

  const isImpersonatingBusiness = user?.impersonating_business

  const referralsVisibilityTest = useAbTest({
    abTestName: 'referrals_visibility',
    shouldTrackExpose: true,
  })

  if (referralsVisibilityTest?.variant !== 'on' || !isReferralsEnabled || isImpersonatingBusiness) {
    return null
  }

  return (
    <>
      <Card>
        <Cell
          prefix={<Image src={asset('gift-box.png')} size={Image.Size.XLarge} />}
          title={translate('title')}
          body={<Text as="span" text={translate('body')} />}
          url={REFERRALS_URL}
          suffix={
            <Icon
              name={ChevronRight24}
              color={Icon.Color.GreyscaleLevel4}
              display={Icon.Display.Block}
            />
          }
        />
      </Card>
      {bottomSpacer}
    </>
  )
}

export default ReferralsBanner
