import { useEffect } from 'react'

import useFetch from 'hooks/useFetch'

import { getFeaturedCollections } from 'data/api'
import { transformFeaturedCollectionsResponse } from 'data/api/transformers/response'

type Props = {
  userId: number
}

const useFetchFeaturedCollections = ({ userId }: Props) => {
  const {
    fetch: fetchCollections,
    transformedData: collections,
    isLoading,
    error,
  } = useFetch(getFeaturedCollections, transformFeaturedCollectionsResponse)

  useEffect(() => {
    fetchCollections({ userId })
  }, [fetchCollections, userId])

  return {
    collections,
    isLoading,
    error,
  }
}

export default useFetchFeaturedCollections
