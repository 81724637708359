'use client'

import useTracking from 'hooks/useTracking'
import useStartBumpCheckout from 'hooks/useStartBumpCheckout'

import { clickEvent } from 'libs/common/event-tracker/events'
import { navigateToPage } from 'libs/utils/window'
import { getBumpableItemDataFromModel } from 'data/utils/bump'

import { getUserItems } from 'data/api'
import { transformUserItemsResponse } from 'data/api/transformers/response'

import { ItemFilterApiParam } from 'constants/item-filter'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { BUMP_MULTIPLE_ITEM_SELECTION_URL } from 'constants/routes'
import { VasImages } from 'constants/vas-entry-point'

import { ProductItemModel, VasEntryPointModel } from 'types/models'

import VasBanner from '../../VasBanner'

type Props = {
  bumpBanner?: VasEntryPointModel
  userId: number
  getSingleBumpableItem: () => ProductItemModel | undefined
}

const PER_PAGE = 1
const DEFAULT_PAGE = 0

const BumpBanner = ({ bumpBanner, userId, getSingleBumpableItem }: Props) => {
  const { track } = useTracking()
  const { startCheckout } = useStartBumpCheckout()

  if (!bumpBanner) return null

  async function onClickBumpBanner() {
    let bumpableItem: ProductItemModel | undefined

    bumpableItem = getSingleBumpableItem()

    if (bumpableItem) {
      startCheckout(getBumpableItemDataFromModel(bumpableItem))

      return
    }

    const response = await getUserItems({
      userId,
      filter: ItemFilterApiParam.Pushable,
      perPage: PER_PAGE,
      currentPage: DEFAULT_PAGE,
    })

    if ('errors' in response) return

    const transformedResponse = transformUserItemsResponse(response)
    bumpableItem = transformedResponse.length ? transformedResponse[0] : undefined

    if (!bumpableItem) return

    startCheckout(getBumpableItemDataFromModel(bumpableItem))
  }

  const handleBumpBannerClick = () => {
    track(
      clickEvent({
        screen: Screen.CurrentUserProfile,
        target: ClickableElement.MultiplePushUp,
      }),
    )
    if (bumpBanner.bumpableItemCount === 1) {
      onClickBumpBanner()

      return
    }
    navigateToPage(BUMP_MULTIPLE_ITEM_SELECTION_URL)
  }

  const bannerProps = {
    ...bumpBanner,
    onClick: handleBumpBannerClick,
    testId: 'bump-banner',
    icon: VasImages.VasBump,
  }

  return <VasBanner {...bannerProps} />
}

export default BumpBanner
