'use client'

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { InfoCircle24, Stack24, Percent24, PictureTshirt24, X24 } from '@vinted/monochrome-icons'
import {
  Text,
  Dialog,
  Button,
  Navigation,
  Cell,
  Image,
  Divider,
  Icon,
  ColorCode,
  Spacer,
  InfoBanner,
} from '@vinted/web-ui'

import useAsset from 'hooks/useAsset'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useAbTest from 'hooks/useAbTest'

import ContentLoader from 'components/ContentLoader'
import ScrollableArea from 'components/ScrollableArea'
import FaqEntryUrl from 'components/FaqEntryUrl'

import { formatCurrencyAmount } from 'libs/utils/formatString'
import { navigateToPage } from 'libs/utils/window'
import { clickEvent } from 'libs/common/event-tracker/events'
import { FEATURED_COLLECTION_URL } from 'constants/routes'
import { FaqEntryType } from 'constants/faq-entry'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { CollectionsPlanPricingModel } from 'types/models'
import { exposeVasBannerLock } from 'data/api'

type Props = {
  isShown: boolean
  isLoading: boolean
  cheapestPlan: CollectionsPlanPricingModel | undefined
  maxActiveCollectionsCount: number
  locked?: boolean
  lockedNote?: string
  onClose: () => void
  onPriceInfoClick: () => void
}

const MultipleCollectionsOnboardingModal = ({
  isShown,
  isLoading,
  cheapestPlan,
  maxActiveCollectionsCount,
  locked,
  lockedNote,
  onClose,
  onPriceInfoClick,
}: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('featured_collection.multiple.onboarding_modal')
  const { locale } = useIntl()
  const asset = useAsset('/assets/featured-collection')
  const vasBannerLockAbTest = useAbTest({ abTestName: 'vas_banner_lock' })

  useEffect(() => {
    if (!vasBannerLockAbTest?.variant) return
    if (!cheapestPlan) return

    exposeVasBannerLock()
  }, [vasBannerLockAbTest, cheapestPlan])

  function handleCreateCollectionsClick() {
    track(
      clickEvent({ screen: Screen.FeaturedCollection, target: ClickableElement.CreateCollection }),
    )

    navigateToPage(FEATURED_COLLECTION_URL)
  }

  function renderNavigation() {
    return (
      <Navigation
        right={
          <Button
            iconName={X24}
            theme="amplified"
            styling={Button.Styling.Flat}
            testId="multiple-collections-onboarding-close-button"
            onClick={onClose}
            aria={{
              'aria-label': translate('a11y.actions.close'),
            }}
          />
        }
        body={
          <Text
            as="h2"
            type={Text.Type.Title}
            width={Text.Width.Parent}
            alignment={Text.Alignment.Center}
            text={translate('title')}
          />
        }
      />
    )
  }

  function renderContent() {
    if (isLoading) {
      return (
        <ContentLoader
          styling={ContentLoader.Styling.Wide}
          testId="multiple-collections-onboarding-loader"
        />
      )
    }

    const imageSrc = asset('featured-collection-onboarding.png', {
      theme: { dark: 'dark/featured-collection-onboarding.png' },
    })

    return (
      <>
        <Image src={imageSrc} testId="multiple-collections-onboarding-image" />
        <Cell>
          <Text as="h1" text={translate('intro.title')} type={Text.Type.Heading} />
        </Cell>
        <Cell
          title={translate('intro.groups.title')}
          body={translate(
            'intro.groups.body',
            { count: maxActiveCollectionsCount },
            { count: maxActiveCollectionsCount },
          )}
          prefix={<Icon name={Stack24} color={ColorCode.GreyscaleLevel3} />}
        />
        <Cell
          title={translate('intro.sale.title')}
          body={translate('intro.sale.body')}
          prefix={<Icon name={Percent24} color={ColorCode.GreyscaleLevel3} />}
        />
        <Cell
          title={translate('intro.feature.title')}
          body={
            <>
              <Text as="span">{translate('intro.feature.body')}</Text>
              <Spacer size={Spacer.Size.X2Large} />
              <FaqEntryUrl
                type={FaqEntryType.Collection}
                render={url => (
                  <a href={url} target="_blank" rel="noreferrer">
                    {translate('actions.learn_more')}
                  </a>
                )}
              />
              <Spacer size={Spacer.Size.Large} />
            </>
          }
          prefix={<Icon name={PictureTshirt24} color={ColorCode.GreyscaleLevel3} />}
        />
      </>
    )
  }

  function renderDeal() {
    if (!cheapestPlan) return null

    const totalPrice = formatCurrencyAmount(cheapestPlan.totalPrice, locale)

    return (
      <div className="u-ui-margin-horizontal-large u-ui-margin-vertical-x-small">
        <Cell
          styling={Cell.Styling.Tight}
          title={translate(
            'duration_day_count',
            { count: cheapestPlan.effectiveDays },
            { count: cheapestPlan.effectiveDays },
          )}
          suffix={
            <Button
              styling={Button.Styling.Flat}
              theme="amplified"
              text={translate('price_from', { price: totalPrice })}
              icon={<Icon name={InfoCircle24} color={Icon.Color.GreyscaleLevel3} />}
              iconPosition={Button.IconPosition.Right}
              onClick={onPriceInfoClick}
              testId="multiple-collections-onboarding-price-info-button"
            />
          }
        />
      </div>
    )
  }

  function renderConfirmationAction() {
    if (!cheapestPlan) return null

    return (
      <div className="u-ui-margin-horizontal-large u-ui-margin-bottom-large">
        <Button
          text={translate('actions.create')}
          styling={Button.Styling.Filled}
          onClick={handleCreateCollectionsClick}
          testId="multiple-collections-onboarding-create-button"
        />
      </div>
    )
  }

  function renderLockedInfoBanner() {
    return (
      <div className="u-ui-margin-horizontal-large u-ui-margin-bottom-large">
        <InfoBanner body={lockedNote} styling={InfoBanner.Styling.Tight} testId="locked-banner" />
      </div>
    )
  }

  return (
    <Dialog show={isShown} hasScrollableContent testId="multiple-collections-onboarding-modal">
      <div className="u-fill-width">
        {renderNavigation()}
        <ScrollableArea>{renderContent()}</ScrollableArea>
        <Divider />
        {renderDeal()}
        {locked && renderLockedInfoBanner()}
        {!locked && renderConfirmationAction()}
      </div>
    </Dialog>
  )
}

export default MultipleCollectionsOnboardingModal
