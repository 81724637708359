import { SellerBadgeType } from 'components/SellerBadge/SellerBadge'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

export enum ReplyTimeThreshold {
  VerySlow = 0,
  Slow = 24,
  Moderate = 12,
  Fast = 9,
  VeryFast = 6,
}

export enum QuickReplierProgress {
  VerySlow = 0,
  Slow = 0.25,
  Moderate = 0.5,
  Fast = 0.75,
  VeryFast = 1,
}

export const MAP_BADGE_TYPE_TO_SCREEN = {
  [SellerBadgeType.ActiveLister]: Screen.ListerBadgeInfo,
  [SellerBadgeType.FastReplier]: Screen.ReplierBadgeInfo,
}

export const MAP_BADGE_TYPE_TO_ACTION_TARGET = {
  [SellerBadgeType.ActiveLister]: ClickableElement.UploadItem,
  [SellerBadgeType.FastReplier]: ClickableElement.CheckInbox,
}

export const MAP_BADGE_TYPE_TO_BADGE_TARGET = {
  [SellerBadgeType.ActiveLister]: ClickableElement.ListerBadge,
  [SellerBadgeType.FastReplier]: ClickableElement.ReplierBadge,
}
