'use client'

import { Spacer } from '@vinted/web-ui'

import { Screen } from 'constants/tracking/screens'
import { TaxpayerBannerModel } from 'types/models'

import TaxpayerBanner from 'components/TaxpayerBanner'
import { TaxpayerBannerRenderLocation } from 'constants/taxpayer'
import useFeatureSwitch from 'hooks/useFeatureSwitch'

type Props = {
  taxpayerBanner?: TaxpayerBannerModel
}

const ProfileTaxpayerBanner = ({ taxpayerBanner }: Props) => {
  const isTaxpayerSpecialVerificationBannerFsEnabled = useFeatureSwitch(
    'web_special_verification_taxpayers_banners',
  )

  if (!taxpayerBanner) return null

  const isTaxpayersSpecialVerificationBanner = taxpayerBanner.isSpecialVerification

  const shouldDisplayBanner =
    !isTaxpayersSpecialVerificationBanner ||
    (isTaxpayersSpecialVerificationBanner && isTaxpayerSpecialVerificationBannerFsEnabled)

  if (!shouldDisplayBanner) return null

  return (
    <>
      <TaxpayerBanner
        banner={taxpayerBanner}
        isBannerInFeed={false}
        screen={Screen.CurrentUserProfile}
        renderLocation={TaxpayerBannerRenderLocation.Wardrobe}
      />
      <Spacer size={Spacer.Size.Large} />
    </>
  )
}

export default ProfileTaxpayerBanner
