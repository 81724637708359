import { useEffect } from 'react'

import useFetch from 'hooks/useFetch'
import { getUserById } from 'data/api'
import { transformUserResponse } from 'data/api/transformers/response'

const useFetchUserById = ({ id, localize }: { id: number; localize: boolean }) => {
  const {
    fetch: fetchUserById,
    data,
    isLoading,
    error,
  } = useFetch(getUserById, transformUserResponse)

  useEffect(() => {
    fetchUserById({ id, localize })
  }, [fetchUserById, id, localize])

  return { data, isLoading, error }
}

export default useFetchUserById
