import useAbTest from 'hooks/useAbTest'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import useSession from 'hooks/useSession'

type Props = {
  isUserBusiness: boolean
  abTestName: string
}

const useSellerBadgeAbTest = ({ isUserBusiness, abTestName }: Props) => {
  const isFastReplierBadgeEnabled = useFeatureSwitch('web_fast_replier_badge')

  const isCurrentUserBusiness = useSession().user?.business

  const bothUsersNotBusiness = !isCurrentUserBusiness && !isUserBusiness

  const abTest = useAbTest({
    abTestName,
    shouldTrackExpose: bothUsersNotBusiness && isFastReplierBadgeEnabled,
  })

  useAbTest({
    abTestName: `${abTestName}_aa_test`,
    shouldTrackExpose: bothUsersNotBusiness && isFastReplierBadgeEnabled,
  })

  return abTest
}

export default useSellerBadgeAbTest
