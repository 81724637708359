'use client'

import { Component, Fragment, ReactComponentElement, ReactNode } from 'react'
import { Card, Cell, Button } from '@vinted/web-ui'
import uuid from 'uuid'

import { BreakpointProps, withBreakpoint } from 'components/Breakpoint'

type Props = BreakpointProps & {
  title?: ReactNode
  body?: ReactNode
  prefix?: ReactNode
  actions?: Array<ReactComponentElement<typeof Button>>
  highlighted?: boolean
  children?: ReactNode
}

class Banner extends Component<Props> {
  renderActions = () => {
    const { actions } = this.props

    if (!actions?.length) return null

    return (
      <div className="banner-actions__container">
        {actions.map(action => (
          <Fragment key={uuid.v4()}>{action}</Fragment>
        ))}
      </div>
    )
  }

  renderBanner() {
    const { title, body, prefix, children, breakpoints, highlighted } = this.props

    const suffix = this.renderActions()

    const content = children || body

    return (
      <Cell styling={Cell.Styling.Tight} highlighted={highlighted}>
        <Cell prefix={prefix} suffix={breakpoints.phones ? null : suffix} highlighted={highlighted}>
          {title && <div>{title}</div>}
          {content && <div>{content}</div>}
        </Cell>
        {!!suffix && (
          <div className="u-phones-only u-ui-margin-horizontal-large u-ui-margin-bottom-large">
            {suffix}
          </div>
        )}
      </Cell>
    )
  }

  render() {
    const { breakpoints } = this.props

    if (breakpoints.phones) {
      return this.renderBanner()
    }

    return (
      <div className="u-fill-width">
        <Card>
          <div className="u-overflow-hidden">{this.renderBanner()}</div>
        </Card>
      </div>
    )
  }
}

export default withBreakpoint(Banner)
