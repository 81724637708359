'use client'

import { Card, Cell, Icon, Spacer, Text } from '@vinted/web-ui'
import { Plus32 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import { clickEvent } from 'libs/common/event-tracker/events'
import { navigateToPage } from 'libs/utils/window'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { FEATURED_COLLECTION_URL } from 'constants/routes'

const AddNewCollectionButton = () => {
  const translate = useTranslate()
  const { track } = useTracking()

  function handleAddCollectionClick() {
    track(clickEvent({ screen: Screen.CurrentUserProfile, target: ClickableElement.AddCollection }))

    navigateToPage(FEATURED_COLLECTION_URL)
  }

  return (
    <div>
      <Card>
        <div className="u-flexbox u-fill-height multiple-collections-block__square-box-container">
          <Cell
            type={Cell.Type.Navigating}
            theme="transparent"
            onClick={handleAddCollectionClick}
            testId="multiple-collections-add-collection-button"
            aria={{ 'aria-labelledby': 'add-collection-title' }}
          >
            <div className="u-flexbox u-justify-content-center">
              <Icon color={Icon.Color.Primary} name={Plus32} display={Icon.Display.InlineBlock} />
            </div>
          </Cell>
        </div>
      </Card>
      <Spacer />
      <div>
        <Text
          as="h2"
          id="add-collection-title"
          text={translate('featured_collection.multiple.collections_block.actions.add')}
          type={Text.Type.Title}
          theme="amplified"
        />
      </div>
    </div>
  )
}

export default AddNewCollectionButton
