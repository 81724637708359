'use client'

import { useState } from 'react'
import { Cell, Loader, Spacer, Text, EmptyState, Label, Divider, Animation } from '@vinted/web-ui'

import useAsset from 'hooks/useAsset'
import useBreakpoint from 'hooks/useBreakpoint'
import useTranslate from 'hooks/useTranslate'

import { UiState } from 'constants/ui'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { InfoBannerScreen } from 'constants/info-banner'

import InfiniteScroll from 'components/InfiniteScroll'
import InfoBanner from 'components/InfoBanner'
import SeparatedList from 'components/SeparatedList'

import useFeatureSwitch from 'hooks/useFeatureSwitch'

import FeedbackItem from './FeedbackItem'
import FeedbackSummary from './FeedbackSummary'
import FeedbackFilters from './FeedbackFilters/FeedbackFilters'
import { FeedbackFilterType } from '../types'

import { useFeedbackContext } from './FeedbackProvider'

type Props = {
  userId: number
  username: string
  readMoreUrl?: string
  isContentTranslationEnabled: boolean
  showTitle: boolean
  viewingSelf: boolean
}

const ProfileFeedback = ({
  userId,
  username,
  readMoreUrl,
  isContentTranslationEnabled,
  showTitle,
  viewingSelf = false,
}: Props) => {
  const translate = useTranslate('user.feedback')
  const asset = useAsset('/assets/animations')
  const breakpoints = useBreakpoint()

  const { ids, setIds, uiState, showFilters, resetCurrentPage, getEndReached, fetchFeedback } =
    useFeedbackContext()

  const endReached = getEndReached()

  const isTransparentFeedbackEnabled = useFeatureSwitch('web_transparent_autofeedback')

  const [selectedFeedbackFilterType, setSelectedFeedbackFilterType] = useState(
    FeedbackFilterType.All,
  )

  const getFeedback = () => {
    if (endReached) return undefined

    return fetchFeedback(userId, selectedFeedbackFilterType)
  }

  const renderFeedbackItem = (id: number) => (
    <FeedbackItem
      key={id}
      id={id}
      userId={userId}
      isContentTranslationEnabled={isContentTranslationEnabled}
    />
  )

  const renderFeedbackItems = () => {
    const separator = (
      <div className="u-ui-margin-vertical-medium">
        <Divider />
      </div>
    )

    return <SeparatedList separator={separator}>{ids.map(renderFeedbackItem)}</SeparatedList>
  }

  const renderEmptyState = () => {
    if (uiState === UiState.Pending || !endReached || ids.length) return null

    return (
      <EmptyState
        animation={<Animation animationUrl={asset('performance-empty-state.json')} />}
        title={translate('empty_state.title')}
        body={translate('empty_state.body')}
      />
    )
  }

  const renderReadMoreBlock = () => {
    if (!readMoreUrl) return null

    return (
      <Label
        type={Label.Type.Leading}
        text={translate('most_recent')}
        suffix={
          !isTransparentFeedbackEnabled && (
            <a className="u-disable-underline" href={readMoreUrl}>
              {translate('read_more')}
            </a>
          )
        }
      />
    )
  }

  const renderLoader = () => {
    if (uiState !== UiState.Pending) return null

    return (
      <div className="u-flexbox u-justify-content-center">
        <Loader size={Loader.Size.Large} />
      </div>
    )
  }

  const handleFilterChipClicked = (feedbackFilterType: FeedbackFilterType) => {
    setSelectedFeedbackFilterType(feedbackFilterType)

    resetCurrentPage()
    setIds([])
  }

  const renderFeedbackContent = () => {
    return (
      <>
        {renderReadMoreBlock()}
        <Cell>
          <InfiniteScroll
            renderItems={renderFeedbackItems}
            onPageEnd={getFeedback}
            cursor={`feedback-${ids.length}`}
          />
          {renderLoader()}
        </Cell>
      </>
    )
  }

  const renderInfoBanner = () => {
    const target = ClickableElement.MergeReviewsBanner
    if (breakpoints.tabletsUp) {
      return (
        <InfoBanner
          screen={InfoBannerScreen.CurrentUserReviews}
          linkTracking={{ target }}
          theme="inverseExperimental"
        />
      )
    }

    return (
      <div className="u-ui-margin-large">
        <InfoBanner
          screen={InfoBannerScreen.CurrentUserReviews}
          linkTracking={{ target }}
          theme="inverseExperimental"
        />
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <>
        {viewingSelf && renderInfoBanner()}
        {showTitle && (
          <Text as="h1" type={Text.Type.Heading}>
            {translate('title', { username })}
          </Text>
        )}
      </>
    )
  }

  return (
    <SeparatedList separator={<Spacer size={Spacer.Size.Large} />}>
      {renderHeader()}
      <FeedbackSummary userId={userId} />
      <FeedbackFilters
        selectedFeedbackFilterType={selectedFeedbackFilterType}
        showFilters={showFilters}
        onClick={handleFilterChipClicked}
      />
      {renderEmptyState() || renderFeedbackContent()}
    </SeparatedList>
  )
}

export default ProfileFeedback
