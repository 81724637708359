'use client'

import { useEffect } from 'react'

import { ADMIN_SECTION_PROFILE_PAGE_URL } from 'constants/routes'
import { urlWithParams } from 'libs/utils/url'

type Props = {
  userId: number
}

const ProfileAdminSection = ({ userId }: Props) => {
  useEffect(() => {
    const iframe: HTMLIFrameElement = document.getElementById(
      'profile-admin-section-iframe',
    ) as HTMLIFrameElement
    iframe.addEventListener('load', () => {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document

      if (!iframeDocument) return

      // We need to set the overflow to hidden to prevent the iframe from scrolling.
      iframeDocument.body.style.overflow = 'hidden'

      // We need to set the base target to _parent to prevent the iframe from opening
      // links in the iframe itself.
      const baseElement = iframeDocument.createElement('base')
      baseElement.target = '_parent'
      iframeDocument.head.appendChild(baseElement)

      // We need to adjust the height of the iframe depending on the dropdown menu
      // to avoid unnecessary whitespace when it is closed
      const adminDropdownMenus = iframeDocument.querySelectorAll('.dropdown-wrapper')
      const adminMenuContainer: HTMLElement | null = iframeDocument.querySelector(
        '.body-content > .container',
      )

      if (!adminMenuContainer) return

      iframe.style.height = `${adminMenuContainer.offsetHeight}px`

      const updateIframeHeight = () => {
        if (
          !(
            adminDropdownMenus[0]!.classList.contains('open') ||
            adminDropdownMenus[1]!.classList.contains('open')
          )
        ) {
          iframe.style.height = `${adminMenuContainer.offsetHeight}px`

          return
        }

        const openedDropdownMenu: HTMLElement | null = iframeDocument.querySelector(
          '.dropdown-wrapper.open > ul',
        )
        if (!openedDropdownMenu) return

        // we need to add additional 350px due to nested dropdown menus
        iframe.style.height =
          adminMenuContainer.offsetHeight > openedDropdownMenu.offsetHeight + 350
            ? `${adminMenuContainer.offsetHeight}px`
            : `${openedDropdownMenu.offsetHeight + 350}px`
      }

      // to react to dom changes => opening the dropdown menu
      const observer = new MutationObserver(() => {
        updateIframeHeight()
      })

      observer.observe(iframeDocument.body, {
        attributes: true,
        attributeFilter: ['class'],
        childList: true,
        subtree: true,
      })
    })
  }, [])

  const url = urlWithParams(ADMIN_SECTION_PROFILE_PAGE_URL(userId), {
    content_only: 1,
  })

  return (
    <iframe
      id="profile-admin-section-iframe"
      title="Admin section"
      style={{
        border: 'none',
        width: '100%',
      }}
      src={url}
    />
  )
}

export default ProfileAdminSection
