'use client'

import { useState, useEffect } from 'react'
import { Button, Cell, EmptyState, Icon } from '@vinted/web-ui'
import classNames from 'classnames'
import { HorizontalDots24 } from '@vinted/monochrome-icons'

import { addClickListItemTracking } from 'containers/TrackingProvider'
import { ProductItemModel } from 'types/models'
import { ListItemContentType } from 'constants/tracking/content-types'
import { ContentSource } from 'constants/tracking/content-sources'
import { EDIT_FEATURED_COLLECTION_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { clickEvent, impressionEvent } from 'libs/common/event-tracker/events'
import { reloadPage } from 'libs/utils/window'
import { deleteFeaturedCollection } from 'data/api'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import HorizontalScrollArea from 'components/HorizontalScrollArea'
import DropdownMenu from 'components/DropdownMenu'
import DeleteCollectionModal from 'components/FeaturedCollection/DeleteCollectionModal'

import FeaturedItem from './FeaturedItem'

type Props = {
  id: number
  title: string
  items: Array<ProductItemModel>
  viewingSelf: boolean
  allowBump: boolean
  hasItems: boolean
  isActive: boolean
  humanizedTimeLeft?: string
}

const TrackedFeaturedItem = addClickListItemTracking(FeaturedItem)

const FeaturedCollectionBox = ({
  id,
  items,
  title,
  viewingSelf,
  allowBump,
  hasItems,
  isActive,
  humanizedTimeLeft,
}: Props) => {
  const translate = useTranslate('featured_collection')
  const { track } = useTracking()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  useEffect(() => {
    track(
      impressionEvent({
        id,
        position: 0,
        contentSource: ContentSource.FeaturedCollection,
        contentType: ListItemContentType.FeaturedCollection,
      }),
    )
  }, [id, track])

  function trackEditCollection() {
    const trackingEvent = clickEvent({
      screen: Screen.CurrentUserProfile,
      target: ClickableElement.EditCollection,
    })

    track(trackingEvent)
  }

  function getSubtitleText() {
    if (!viewingSelf) return translate('box_title')
    if (!isActive) return translate('box_inactive_subtitle')

    return humanizedTimeLeft
  }

  function openDeleteModal() {
    setIsDeleteModalOpen(true)
  }

  function closeDeleteModal() {
    setIsDeleteModalOpen(false)
  }

  async function handleDeleteCollection() {
    track(clickEvent({ target: ClickableElement.DeleteCollection }))

    await deleteFeaturedCollection({ id })

    reloadPage()
  }

  function renderEditButton() {
    if (!viewingSelf) return null

    if (!isActive) {
      const actions = [
        {
          id: 'edit',
          text: translate('actions.edit'),
          url: EDIT_FEATURED_COLLECTION_URL(id),
          onClick: trackEditCollection,
        },
        { id: 'delete', text: translate('actions.delete'), onClick: openDeleteModal },
      ]

      const dropdownButtonProps: ComponentProps<typeof Button> = {
        styling: Button.Styling.Flat,
        icon: (
          <Icon
            name={HorizontalDots24}
            color={Icon.Color.GreyscaleLevel4}
            testId="collection-actions-menu-button"
          />
        ),
        size: Button.Size.Medium,
      }

      return (
        <>
          <DropdownMenu actions={actions} buttonProps={dropdownButtonProps} />
          <DeleteCollectionModal
            isOpen={isDeleteModalOpen}
            onSubmit={handleDeleteCollection}
            onClose={closeDeleteModal}
          />
        </>
      )
    }

    return (
      <Button
        text={translate('actions.edit')}
        styling={Button.Styling.Flat}
        size={Button.Size.Medium}
        url={EDIT_FEATURED_COLLECTION_URL(id)}
        onClick={trackEditCollection}
        testId="featured-collection-box--action-edit"
      />
    )
  }

  function renderEmptyState() {
    return (
      <EmptyState
        body={translate('empty_state.box.body')}
        action={
          <Button
            text={translate('empty_state.box.action')}
            styling={Button.Styling.Filled}
            url={EDIT_FEATURED_COLLECTION_URL(id)}
          />
        }
      />
    )
  }

  function renderItems() {
    if (!hasItems) return renderEmptyState()

    return (
      <div
        className={classNames('u-overflow-hidden', {
          'featured-collection__items--inactive': !isActive,
        })}
      >
        <HorizontalScrollArea>
          {items.map((item, index) => (
            <TrackedFeaturedItem
              item={item}
              viewingSelf={viewingSelf}
              allowBump={allowBump}
              tracking={{
                id: item.id,
                contentType: ListItemContentType.Item,
                contentSource: ContentSource.FeaturedCollection,
                position: index + 1,
              }}
              isDescriptionHidden={!isActive}
              index={index}
              key={`collection-item-${item.id}`}
            />
          ))}
        </HorizontalScrollArea>
      </div>
    )
  }

  return (
    <div className="featured-collection-container">
      <Cell styling={Cell.Styling.Tight} testId="featured-collection">
        <div className="featured-collection">
          <Cell
            title={title}
            body={getSubtitleText()}
            suffix={renderEditButton()}
            testId="featured-collection-box"
          />
          {renderItems()}
        </div>
      </Cell>
    </div>
  )
}

export default FeaturedCollectionBox
