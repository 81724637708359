'use client'

import { useEffect, useState } from 'react'
import { Cell, Divider, Rating, Spacer, Text, Icon } from '@vinted/web-ui'
import classNames from 'classnames'
import { StarFilled16 } from '@vinted/monochrome-icons'

import useBreakpoint from 'hooks/useBreakpoint'
import useTranslate from 'hooks/useTranslate'
import { FeedbackSummaryModel } from 'types/models'
import { UiState } from 'constants/ui'
import { getFeedbackSummary } from 'data/api'
import { transformFeedbackSummaryDto } from 'data/transformers/feedback-summary'
import ContentLoader from 'components/ContentLoader/ContentLoader'
import ErrorState from 'components/ErrorState/ErrorState'
import FaqEntryUrl from 'components/FaqEntryUrl'
import { FaqEntryType } from 'constants/faq-entry'
import useFeatureSwitch from 'hooks/useFeatureSwitch'

type Props = {
  userId: number
}

const FeedbackSummary = ({ userId }: Props) => {
  const breakpoints = useBreakpoint()
  const translate = useTranslate('user.feedback.summary')

  const isTransparentFeedbackEnabled = useFeatureSwitch('web_transparent_autofeedback')

  const [summary, setSummary] = useState<FeedbackSummaryModel | null>(null)
  const [uiState, setUiState] = useState<UiState>(UiState.Idle)

  useEffect(() => {
    const fetchFeedbackSummary = async () => {
      setUiState(UiState.Pending)

      const response = await getFeedbackSummary(userId)

      if ('errors' in response) {
        setUiState(UiState.Failure)

        return
      }

      const transformedSummary = response.user_feedback_summary
        ? transformFeedbackSummaryDto(response.user_feedback_summary)
        : null

      setSummary(transformedSummary)
      setUiState(UiState.Success)
    }

    if (!isTransparentFeedbackEnabled) return

    fetchFeedbackSummary()
  }, [isTransparentFeedbackEnabled, userId])

  const renderStarWithRatingSection = (rating: number) => {
    const renderRatingWithStar = () => (
      <div className="u-flexbox">
        <Text as="span" text={rating} />
        <Spacer orientation={Spacer.Orientation.Vertical} />
        {rating > 0 && (
          <div className="u-flexbox u-align-items-center">
            <Icon name={StarFilled16} color={Icon.Color.Expose} testId="rating-icon" />
          </div>
        )}
      </div>
    )

    return (
      <Cell
        styling={Cell.Styling.Narrow}
        prefix={!breakpoints.portables && renderRatingWithStar()}
        suffix={breakpoints.portables && renderRatingWithStar()}
      />
    )
  }

  const renderRatingCountSeparationStatsSection = (title: string) => {
    const layoutClassNames = classNames('u-flexbox', {
      'u-nowrap': !breakpoints.portables,
    })

    return (
      <Cell styling={Cell.Styling.Narrow}>
        <div className={layoutClassNames}>
          <Text as="span" bold text={title} />
        </div>
      </Cell>
    )
  }

  const renderRatingCountSeparationStats = () => {
    if (!summary) return null

    return (
      <div className="u-flexbox u-flex-direction-column">
        {renderRatingCountSeparationStatsSection(
          translate('manual_feedback', { count: summary.userFeedbackCount }),
        )}
        {renderRatingCountSeparationStatsSection(
          translate('auto_feedback', { count: summary.systemFeedbackCount }),
        )}
      </div>
    )
  }

  const renderTotalRatingWithSeparationStats = () => {
    if (!summary) return null

    const flexDirectionClass = breakpoints.portables
      ? 'u-flex-direction-column'
      : 'u-flex-direction-row'

    const ratingClassNames = classNames('u-flexbox u-flex-direction-column', {
      'u-align-items-center': breakpoints.portables,
    })

    return (
      <Cell>
        <div className={`u-flexbox u-align-items-center ${flexDirectionClass}`}>
          <p className="rating-score-huge-text">{summary.feedbackRating}</p>
          <Spacer
            orientation={
              breakpoints.portables ? Spacer.Orientation.Horizontal : Spacer.Orientation.Vertical
            }
            size={Spacer.Size.Large}
          />
          <div className={ratingClassNames}>
            <Rating value={summary.feedbackRating} />
            {!breakpoints.portables && <Spacer size={Spacer.Size.Large} />}
            <Text as="span" text={`(${summary.feedbackCount})`} />
          </div>
          <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />
          {!breakpoints.portables && renderRatingCountSeparationStats()}
        </div>
      </Cell>
    )
  }

  const renderInformationLink = () => (
    <div className="u-flexbox u-justify-content-flex-end u-nowrap">
      <Cell
        styling={breakpoints.portables ? Cell.Styling.Narrow : Cell.Styling.Wide}
        prefix={
          <FaqEntryUrl
            type={FaqEntryType.LeavingFeedbackForBuyer}
            render={url => (
              <a href={url} target="_blank" rel="noreferrer">
                {translate('learn_more')}
              </a>
            )}
          />
        }
      />
    </div>
  )

  const renderRatingCountSeparationStars = () => {
    if (!summary) return null

    const manualFeedbackStarSection =
      summary.userFeedbackCount > 0 ? (
        renderStarWithRatingSection(summary.userFeedbackRating)
      ) : (
        <Cell>
          <Spacer />
        </Cell>
      )
    const autoFeedbackStarSection =
      summary.systemFeedbackCount > 0 ? (
        renderStarWithRatingSection(summary.systemFeedbackRating)
      ) : (
        <Cell>
          <Spacer />
        </Cell>
      )

    return (
      <Cell>
        <div className="u-flexbox u-flex-direction-column">
          <div className="u-flexbox">
            {breakpoints.portables &&
              renderRatingCountSeparationStatsSection(
                translate('manual_feedback', { count: summary.userFeedbackCount }),
              )}
            {manualFeedbackStarSection}
          </div>
          <div className="u-flexbox">
            {breakpoints.portables &&
              renderRatingCountSeparationStatsSection(
                translate('auto_feedback', { count: summary.systemFeedbackCount }),
              )}
            {autoFeedbackStarSection}
          </div>
          {breakpoints.portables && renderInformationLink()}
        </div>
      </Cell>
    )
  }

  const layoutClassNames = classNames('u-flexbox', {
    'u-flex-direction-column': breakpoints.portables,
    'u-flex-direction-row': !breakpoints.portables,
  })

  if (uiState === UiState.Pending) return <ContentLoader />

  if (uiState === UiState.Failure) return <ErrorState />

  if (!isTransparentFeedbackEnabled || !summary) return null

  return (
    <>
      <div className={layoutClassNames}>
        {breakpoints.portables && <Spacer size={Spacer.Size.Large} />}
        {renderTotalRatingWithSeparationStats()}
        {renderRatingCountSeparationStars()}
        {!breakpoints.portables && renderInformationLink()}
      </div>
      <Divider />
    </>
  )
}

export default FeedbackSummary
