'use client'

import { Button, Container, Spacer, Text, Icon, Image } from '@vinted/web-ui'
import { Plus24 } from '@vinted/monochrome-icons'

import { ITEM_UPLOAD_URL } from 'constants/routes'

import useTranslate from 'hooks/useTranslate'
import useIsDarkMode from 'hooks/useIsDarkMode'
import useAsset from 'hooks/useAsset'

const EmptyState = () => {
  const translate = useTranslate('profile.items_empty_state')
  const asset = useAsset('/assets/closet')
  const isDarkMode = useIsDarkMode()

  const renderContent = () => {
    return (
      <Container styling={Container.Styling.Wide}>
        <Text
          as="h1"
          text={translate('self_view.title')}
          type={Text.Type.Heading}
          alignment={Text.Alignment.Center}
        />
        <Spacer size={Spacer.Size.XLarge} />
        <Text as="span" text={translate('self_view.subtitle')} alignment={Text.Alignment.Center} />
      </Container>
    )
  }

  const renderMedia = () => {
    return (
      <Image
        size={Image.Size.X4Large}
        src={asset(`closet-phone${isDarkMode ? '-dark' : ''}.svg`)}
      />
    )
  }

  return (
    <div className="profile-empty-state" data-testid="profile-empty-state">
      <Spacer size={Spacer.Size.X2Large} />
      {renderMedia()}
      {renderContent()}
      <div className="profile-empty-state--button">
        <Button
          text={translate('button')}
          url={ITEM_UPLOAD_URL}
          styling={Button.Styling.Filled}
          icon={
            <Icon
              name={Plus24}
              color={Icon.Color.GreyscaleLevel7}
              aria={{ 'aria-hidden': 'true' }}
            />
          }
        />
      </div>
      <Spacer size={Spacer.Size.X2Large} />
    </div>
  )
}

export default EmptyState
